import {defineStore} from 'pinia';
import {computed, ref} from '#imports';
import {fetchFromApi} from '@/composables/useRequest';
import type {
  IEquipment,
  IEquipmentChild,
  IEquipmentInner,
  IEquipmentSidebarList,
} from '@/types/store/products';
import type ICrumbs from '@/types/store/products';

export const useEquipmentStore = defineStore('equipment', () => {
  const titlePage = ref('');
  const isActiveLink = ref('');
  // const meta = ref<IMeta>();
  const equipmentList = ref<IEquipment[]>([]);
  const filterList = ref<IEquipmentInner[]>([]);
  const sideBarList = ref<(IEquipmentSidebarList | IEquipmentChild)[]>([]);
  const info = ref<{
    breadcrumbsList?: ICrumbs[];
  }>({
    breadcrumbsList: [],
  });

  const breadcrumbsList = computed(() => info.value.breadcrumbsList || []);

  const getProducts = async () => {
    const {data} = await fetchFromApi<{data: (IEquipment & IEquipmentSidebarList)[]}>(
      `catalog/?type=full`,
    );
    titlePage.value = 'Оборудование';
    if (info.value === undefined) {
      return;
    }
    info.value.breadcrumbsList = [
      {
        id: 1,
        name: 'Главная',
        isMain: true,
        url: '/',
      },
      {
        id: 2,
        name: 'Оборудование',
        url: '/catalog/',
      },
    ];
    if (sideBarList.value === undefined) {
      return;
    }
    sideBarList.value = [...data];
    equipmentList.value = [...data];
  };

  const getFilterProducts = async (id: number) => {
    const {data} = await fetchFromApi<{data: IEquipment[] & IEquipmentSidebarList}>(
      `catalog/?type=full`,
    );

    data.forEach((item) => {
      const getChild = item.childs.find((child) => child.id === id);
      if (!getChild) {
        return;
      }

      titlePage.value = item.name;
      if (sideBarList.value === undefined || info.value === undefined) {
        return;
      }

      sideBarList.value = [...item.childs];

      info.value.breadcrumbsList = [
        {
          id: 1,
          name: 'Главная',
          isMain: true,
          url: '/',
        },
        {
          id: 2,
          name: 'Оборудование',
          url: '/catalog/',
        },
        {
          id: 3,
          name: item.name,
          isMain: true,
          url: `/catalog/${id}/`,
        },
      ];

      filterList.value = [
        {
          ...item,
          childs: {...getChild},
        },
      ];
    });
  };

  // const getMeta = async () => {
  //   const { API_BASE_URL } = useRuntimeConfig().public;
  //   try {
  //     const res = await $fetch<IMeta>(`meta/?page=/catalog/`, {
  //       baseURL: API_BASE_URL,
  //     });
  //     meta.value = { ...res };
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },

  return {
    titlePage,
    isActiveLink,
    equipmentList,
    filterList,
    sideBarList,

    breadcrumbsList,

    getProducts,
    getFilterProducts,
  };
});
